var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"home"},[_vm._m(0),(_vm.IsMaintenance)?_c('div',{staticClass:"announcements"},[_vm._m(1)]):_vm._e(),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row row-cols-1 row-cols-md-2 section-row mt-md-12 mb-md-8"},[_vm._m(2),_c('div',{staticClass:"col p-0"},[_c('div',{staticClass:"card"},[_c('p',{staticClass:"lead text-center m-0 mt-6"},[_vm._v("Explore Schools, Centers, and Systems")]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('label',{staticClass:"control-label",attrs:{"for":"searchItem"}},[_vm._v("Search by Address, Name, or School System")]),_c('div',{staticClass:"form-group"},[_c('div',{attrs:{"id":"typeaheadSearchBar"}},[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchText),expression:"searchText"}],staticClass:"form-control",attrs:{"placeholder":"Enter Name or Address","aria-label":"Enter Name or Address","autocomplete":"off","size":"md"},domProps:{"value":(_vm.searchText)},on:{"focus":function($event){_vm.forceHide = false},"input":[function($event){if($event.target.composing)return;_vm.searchText=$event.target.value},function($event){_vm.itemSelected = false}]}})]),_c('div',{class:[
                        _vm.itemsToShow() && !_vm.forceHide ? 'd-block' : 'd-none',
                        'list-group',
                        'shadow',
                        'vbt-autcomplete-list',
                        'bg-white',
                      ],attrs:{"id":"wide-div"}},[_c('div',{staticClass:"row row-cols-1 row-cols-md-2"},[_c('div',{staticClass:"col"},[_c('h6',[_vm._v("Explore schools and centers by Address")]),_vm._l((_vm.suggestionsGoogleAPI),function(suggestion,index){return _c('a',{key:`loop2-${index}`,staticClass:"vbst-item p-1 w-100 d-block",attrs:{"role":"button"},domProps:{"innerHTML":_vm._s(
                              `<span class='notranslate'><i class='fas fa-map-marker-alt fa-fw'></i>` +
                              _vm.suggestionHTMLString(suggestion.text) +
                              `</span`
                            )},on:{"click":function($event){$event.preventDefault();return _vm.selectItem($event, suggestion)}}})}),_c('img',{staticClass:"float-right mt-md-1 mb-md-1",attrs:{"src":"/img/powered_by_google_on_white.png","alt":"powered by google"}})],2),_c('div',{staticClass:"col"},[_c('h6',[_vm._v("Go right to your school or center")]),(_vm.suggestionsSchoolDB.length == 0)?_c('div',[_c('i',[_vm._v("No schools/centers found with that search criteria")])]):_vm._l((_vm.suggestionsSchoolDB),function(suggestion,index){return _c('a',{key:`loop1-${index}`,staticClass:"vbst-item p-1 w-100 d-block",attrs:{"role":"button"},domProps:{"innerHTML":_vm._s(
                                `<span  class='notranslate'>` +
                                _vm.suggestionHTMLString(suggestion.text) +
                                `</span`
                              )},on:{"click":function($event){$event.preventDefault();return _vm.selectItem($event, suggestion)}}})})],2)])])])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label",attrs:{"for":"grades"}},[_vm._v("Age / Grade Level")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.schoolSearchModel.gradelevel),expression:"schoolSearchModel.gradelevel"}],staticClass:"custom-select",attrs:{"id":"grades","tabindex":"2"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.schoolSearchModel, "gradelevel", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.gradeList),function(item){return _c('option',{key:`loop3-${item.name}`,domProps:{"value":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('button',{staticClass:"btn bg-doe-dark-blue-darker border-doe-dark-blue-darker text-white",attrs:{"type":"button","id":"searchButton","tabindex":"3","disabled":_vm.invalidSearchCriteria},on:{"click":function($event){$event.preventDefault();return _vm.onSearchClick()}}},[_c('span',{staticClass:"fa fa-search text-white"}),_vm._v(" Search ")])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',{staticClass:"doe-page-title py-2"},[_c('h2',{staticClass:"fst-normal p-2 p-md-4"},[_vm._v("Louisiana School and Center Finder")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row w-100"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"alert alert-warning bg-warning-10 alert-dismissable fade show font-weight-normal mb-6",attrs:{"role":"alert"}},[_c('div',[_c('span',[_c('b',[_c('u',[_vm._v("Note to all Users:")])]),_vm._v(" There is a known issues with the system not displaying Inspections. We are working to correct this as soon as we can. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col ml-2 mb-8 m-md-0 align-self-md-center"},[_c('img',{staticClass:"d-none d-xl-block ml-xl-15",attrs:{"id":"mainLogoOld","src":require("../assets/HomeWithBinacular-Nobg.png"),"alt":"SchoolFinder Home"}}),_c('img',{staticClass:"d-block d-xl-none ml-lg-15",attrs:{"id":"mainLogo","src":require("../assets/HomeWithBinacular_PhonePlus-Nobg.png"),"alt":"SchoolFinder Home"}})])
}]

export { render, staticRenderFns }